<template>
  <main class="view view--task-saved">
    <section class="header">
      <div class="header__left"></div>

      <div class="header__center">
        <p class="title">
          {{ this.$helper.ucfirst(this.$moment(date).format("dddd")) }}
        </p>
        <p class="subtitle">{{ this.$moment(date).format("DD/MM/YYYY") }}</p>
      </div>

      <div class="header__right">
        <button
          class="btn btn-close"
          v-on:click="$router.push({ name: 'day', query: { date: date } })"
        ></button>
      </div>
    </section>

    <section>
      <div class="message message--success">
        <img
          class="message__icon"
          src="/img/icon-check-circle-success.png"
          alt=""
        />
        <div class="message__text">
          {{ $t("Timeregistration succesfully saved") }}
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "taskSaved",
  mounted: function () {
    if (!this.$route.query.date) {
      this.$router.push({
        name: "day",
        query: { date: this.$route.query.date },
      });
      return;
    }
  },
  data: function () {
    return {
      date: this.$route.query.date,
    };
  },
};
</script>
